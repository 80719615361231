import { css } from "@emotion/react";
import { StyleSheet } from "aphrodite";
import { equalBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface IGridStyle {
  grid: any;
  hidePhone: any;
  hideTablet: any;
  hideDesktop: any;
  col1: any;
  col2: any;
  col3: any;
  col4: any;
  col5: any;
  col6: any;
  col7: any;
  col8: any;
  col9: any;
  col10: any;
  col11: any;
  col12: any;
  col4_phone: any;
  col8_tablet: any;
}

const phone1col = 25;
const tablet1col = 12.5;
const desktop1col = 8.3333333333;

export const gridStyle: IGridStyle = StyleSheet.create({
  grid: {
    display: "flex",
    flexFlow: "row wrap",
    margin: "0 auto 0 auto",
    alignItems: "stretch",
  },
  hidePhone: {
    [equalBreakpoint("phone")]: {
      display: "none",
    },
  },
  hideTablet: {
    [equalBreakpoint("tablet")]: {
      display: "none",
    },
  },
  hideDesktop: {
    [equalBreakpoint("desktop")]: {
      display: "none",
    },
  },
  col1: {
    [equalBreakpoint("phone")]: {
      width: `${phone1col}%`,
    },
    [equalBreakpoint("tablet")]: {
      width: `${tablet1col}%`,
    },
    [equalBreakpoint("desktop")]: {
      width: `${desktop1col}%`,
    },
  },
  col2: {
    [equalBreakpoint("phone")]: {
      width: `${phone1col * 2}%`,
    },
    [equalBreakpoint("tablet")]: {
      width: `${tablet1col * 2}%`,
    },
    [equalBreakpoint("desktop")]: {
      width: "16.6666666667%",
    },
  },
  col3: {
    [equalBreakpoint("phone")]: {
      width: `${phone1col * 3}%`,
    },
    [equalBreakpoint("tablet")]: {
      width: `${tablet1col * 3}%`,
    },
    [equalBreakpoint("desktop")]: {
      width: "25%",
    },
  },
  col4: {
    width: "100%",
    [equalBreakpoint("tablet")]: {
      width: `${tablet1col * 4}%`,
    },
    [equalBreakpoint("desktop")]: {
      width: "33.3333333333%",
    },
  },
  col5: {
    width: "100%",
    [equalBreakpoint("tablet")]: {
      width: `${tablet1col * 5}%`,
    },
    [equalBreakpoint("desktop")]: {
      width: "41.6666666667%",
    },
  },
  col6: {
    width: "100%",
    [equalBreakpoint("tablet")]: {
      width: `${tablet1col * 6}%`,
    },
    [equalBreakpoint("desktop")]: {
      width: "50%",
    },
  },
  col7: {
    width: "100%",
    [equalBreakpoint("tablet")]: {
      width: `${tablet1col * 7}%`,
    },
    [equalBreakpoint("desktop")]: {
      width: "58.3333333333%",
    },
  },
  col8: {
    width: "100%",
    [equalBreakpoint("desktop")]: {
      width: "66.6666666667%",
    },
  },
  col9: {
    width: "100%",
    [equalBreakpoint("desktop")]: {
      width: "75%",
    },
  },
  col10: {
    width: "100%",
    [equalBreakpoint("desktop")]: {
      width: "83.3333333333%",
    },
  },
  col11: {
    width: "100%",
    [equalBreakpoint("desktop")]: {
      width: "91.6666666667%",
    },
  },
  col12: {
    width: "100%",
  },
  col4_phone: {
    [equalBreakpoint("phone")]: {
      width: "100%",
    },
  },
  col8_tablet: {
    [equalBreakpoint("tablet")]: {
      width: "100%",
    },
  },
});

// for emotion
export const gridLayoutStyle: IGridStyle = {
  grid: css`
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto 0 auto;
    align-items: stretch;
  `,
  hidePhone: css`
    ${equalBreakpoint("phone")} {
      display: none;
    }
  `,
  hideTablet: css`
    ${equalBreakpoint("tablet")} {
      display: none;
    }
  `,
  hideDesktop: css`
    ${equalBreakpoint("desktop")} {
      display: none;
    }
  `,
  col1: css`
    ${equalBreakpoint("phone")} {
      width: ${phone1col}%;
    }
    ${equalBreakpoint("tablet")} {
      width: ${tablet1col}%;
    }
    ${equalBreakpoint("desktop")} {
      width: ${desktop1col}%;
    }
  `,
  col2: css`
    ${equalBreakpoint("phone")} {
      width: ${phone1col * 2}%;
    }
    ${equalBreakpoint("tablet")} {
      width: ${tablet1col * 2}%;
    }
    ${equalBreakpoint("desktop")} {
      width: 16.6666666667%;
    }
  `,
  col3: css`
    ${equalBreakpoint("phone")} {
      width: ${phone1col * 3}%;
    }
    ${equalBreakpoint("tablet")} {
      width: ${tablet1col * 3}%;
    }
    ${equalBreakpoint("desktop")} {
      width: 25%;
    }
  `,
  col4: css`
    width: 100%;
    ${equalBreakpoint("tablet")} {
      width: ${tablet1col * 4}%;
    }
    ${equalBreakpoint("desktop")} {
      width: 33.3333333333%;
    }
  `,
  col5: css`
    width: 100%;
    ${equalBreakpoint("tablet")} {
      width: ${tablet1col * 5}%;
    }
    ${equalBreakpoint("desktop")} {
      width: 41.6666666667%;
    }
  `,
  col6: css`
    width: 100%;
    ${equalBreakpoint("tablet")} {
      width: ${tablet1col * 6}%;
    }
    ${equalBreakpoint("desktop")} {
      width: 50%;
    }
  `,
  col7: css`
    width: 100%;
    ${equalBreakpoint("tablet")} {
      width: ${tablet1col * 7}%;
    }
    ${equalBreakpoint("desktop")} {
      width: 58.3333333333%;
    }
  `,
  col8: css`
    width: 100%;
    ${equalBreakpoint("desktop")} {
      width: 66.6666666667%;
    }
  `,
  col9: css`
    width: 100%;
    ${equalBreakpoint("desktop")} {
      width: 75%;
    }
  `,
  col10: css`
    width: 100%;
    ${equalBreakpoint("desktop")} {
      width: 83.3333333333%;
    }
  `,
  col11: css`
    width: 100%;
    ${equalBreakpoint("desktop")} {
      width: 91.6666666667%;
    }
  `,
  col12: css`
    width: 100%;
  `,
  col4_phone: css`
    ${equalBreakpoint("phone")} {
      width: 100%;
    }
  `,
  col8_tablet: css`
    ${equalBreakpoint("tablet")} {
      width: 100%;
    }
  `,
};
